/**
 * Titel: IPURE
 * Beskrivelse: Globale funktioner til kald af iPure-personsøgning - bruges på kort og i nyt medarbejderdesign
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

AU.ipure = {
    source: {
        searchCrossSite: '//ipure.nfit.au.dk/searchPeople.php?sort=lno&q=',
        searchDetailsCrossSite: '//ipure.nfit.au.dk/current/da_DK/lno/',
        photos: 'http://ipure.nfit.au.dk/current/'
    },
    getPerson: function(xml) {
        var name = $(xml).find('name');
        var room = name.attr('room');
        var lastname = name.attr('lastName');
        var firstname = name.attr('firstName');
        var details = $(xml).find('details');
        var detailsSource = details.attr('external');

        var person = {
            lastname: lastname,
            firstname: firstname,
            room: room,
            detailsSource: detailsSource
        };

        return person;
    },
    getPersonDetails: function(xml, func, multipleDepartments) {

        var nameNode = $(xml).find('name');
        var photoNode = $(xml).find('photo');
        var addressNode = $(xml).find('address');
        var webNode = $(xml).find('web');
        var phoneNode = $(xml).find('phone');
        var departmentNode = $(xml).find('department');

        var firstName = nameNode.attr('firstName');
        var lastName = nameNode.attr('lastName');
        var auid = nameNode.attr('employeeId');
        var photoSrc = photoNode.attr('external');
        var centerMap, street, zip, city, address;

        // Vi løber alle tilknyttede enheder igennem og finder ud af, om adresserne er forskellige
        var found_addresses = [];
        for (var j = 0; j < departmentNode.length; j++) {
            street = $(addressNode[j]).attr('street');
            zip = $(addressNode[j]).attr('zip');
            city = $(addressNode[j]).attr('city');
            address = street + ", " + zip + " " + city;
            var found = false;
            for (var k = 0; k < found_addresses.length; k++) {
                if (found_addresses[k] === address) {
                    found = true;
                }
            }
            if (j === 0 || !found) {
                found_addresses.push(address);
            }
        }

        var email;
        var officePhone;
        var buildingNumber;
        var personHTML = '<div class="vcard">';
        // Vi løber alle enheder igennem
        for (var i = 0; i < departmentNode.length; i++) {
            street = $(addressNode[i]).attr('street');
            zip = $(addressNode[i]).attr('zip');
            city = $(addressNode[i]).attr('city');
            buildingNumber = $(addressNode[i]).attr('building');
            var room = $(addressNode[i]).attr('room');
            officePhone = $(phoneNode[i]).attr('office');
            var mobilePhone = $(phoneNode[i]).attr('mobile');
            email = $(webNode[i]).attr('email');

            personHTML += '<h2 class="fn"><a class="url" href="http://au.dk/' + email + '">' + firstName + ' ' + lastName + '</a></h2>';
            personHTML += '<p>';
            if (typeof photoSrc !== 'undefined') {
                personHTML += '<a class="photo" href="http://au.dk/' + email + '"><img style="width: 70px; float: right; margin-left: 1em;" src="' + this.source.photos + photoSrc.replace('../../../', '').replace('.JPG', '.jpg') + '" alt="" title="" /></a>';
            }

            if (found_addresses.length === 1) {
                for (var n = 0; n < departmentNode.length; n++) {
                    personHTML += '<span class="org">' + $(departmentNode[n]).attr('name') + '</span><br />';
                }
                centerMap = true;
            } else {
                personHTML += '<span class="org">' + $(departmentNode[i]).attr('name') + '</span><br />';
                centerMap = false;
            }

            if (typeof buildingNumber !== 'undefined') {
                personHTML += (AU.pageLang === "da" ? "Bygning " : "Building ") + buildingNumber;
                if (typeof room !== 'undefined') {
                    personHTML += ", " + (AU.pageLang === "da" ? "Kontor " : "Office ") + room + "<br />";
                } else {
                    personHTML += "";
                }
            }

            personHTML += '<span class="adr"><span class="street-address">' + street + '</span>, <span class="postal-code">' + zip + '</span> <span class="locality">' + city + '</span></span><br /><br />';

            if (typeof officePhone !== 'undefined') {
                if (AU.helpers.isMobileDevice()) {
                    personHTML += 'T: <a class="tel" href="tel:' + officePhone + '">' + officePhone + '</a><br />';
                } else {
                    personHTML += 'T: <span class="tel">' + officePhone + '</span><br />';
                }
            }

            if (typeof mobilePhone !== 'undefined') {
                if (AU.helpers.isMobileDevice()) {
                    personHTML += 'M: <a class="tel" href="tel:' + mobilePhone + '">' + mobilePhone + '</a><br />';
                } else {
                    personHTML += 'M: <span class="tel">' + mobilePhone + '</span><br />';
                }
            }

            if (typeof email !== 'undefined') {
                personHTML += 'E: <a class="email" href="mailto:' + email + '">' + email + '</a><br />';
            }

            /*
            if (typeof auid !== 'undefined') {
                personHTML += 'ID: <span class="auid">AU' + auid + '</span>';
            }
            */

            personHTML += '</p>';

            if (i === 0 && !multipleDepartments) {
                break;
            }

        }

        personHTML += '</div>';

        if (typeof func === 'function') {
            func(personHTML, buildingNumber, email, officePhone, address, auid, centerMap);
        }

    },
    getPeople: function(xml) {
        var people = [];
        var peopleXML = $(xml).find('person');

        $.each(peopleXML, function(i, p) {
            var name = $(p).find('name');
            var room = name.attr('room');
            var lastname = name.attr('lastName');
            var firstname = name.attr('firstName');
            var details = $(p).find('details');
            var detailsSource = details.attr('external');

            var person = {
                id: i,
                lastname: lastname,
                firstname: firstname,
                room: room,
                detailsSource: detailsSource,
                label: firstname + ' ' + lastname,
                value: firstname + ' ' + lastname,
                url: detailsSource
            };

            people[i] = person;
        });

        return people;
    }
};