/**
 * Titel: NEWS
 * Beskrivelse: de 'nye nyheder', som indlæses asynkront og åbnes (nogle gange) i overlay
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

const disqus_no_style = true;
const disqus_shortname = 'aukom';
let disqus_url = '';
let disqus_loaded = false;

$.ajaxSetup({
    cache: true
});

AU.news = {
    feeds: [],
    scriptLoaded: false,
    loadExternal: function () {
        this.setPopup();
        const news = new AUNews(AU.pageLang, AU.news.feeds);
        news.init();
        new AUNewsCollage(AU.pageLang, document.querySelector('#featured-content'));
    },
    setPopup: function () {
        var body = document.querySelector('body');
        var popup = document.createElement('div');
        popup.setAttribute('id', 'au_news_popup');
        body.appendChild(popup);
    },
    init: function () {
        const fc = document.querySelector('#featured-content');
        if ((this.feeds && this.feeds.length) > 0 || fc) {
            if (!this.scriptLoaded) {
                const componentsUrl = (typeof window.componentsUri !== 'undefined' ? window.componentsUri : AU.cdn) + '/components/umd/news.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.news.loadExternal();
                    AU.news.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
}

String.prototype.hashCode = function () {
    var hash = 0, i, ch, l;
    if (this.length === 0) {
        return '_' + Math.abs(hash);
    }
    for (i = 0, l = this.length; i < l; i++) {
        ch = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + ch;
        hash |= 0; // Convert to 32bit integer
    }
    return '_' + Math.abs(hash);
};

//http://stackoverflow.com/questions/9235304/how-to-replace-the-location-hash-and-only-keep-the-last-history-entry
(function (namespace) {
    if ('replaceState' in history) {
        namespace.replaceHash = function (newhash) {
            if (('' + newhash).charAt(0) !== '#') {
                newhash = '#' + newhash;
            }
            history.replaceState('', '', newhash);
        };
    } else {
        var hash = location.hash;
        namespace.replaceHash = function (newhash) {
            if (location.hash !== hash) {
                history.back();
            }
            location.hash = newhash;
        };
    }
})(window);