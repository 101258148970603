$.ajaxSetup({
    cache: true
});
var CludoSearch;
AU.search = {
    init: function (searchUrl) {
        // Cludo
        var primaryDanish = typeof window.primaryDanish == 'undefined' ? true : window.primaryDanish;
        var baseUrlAppend = primaryDanish ? (AU.pageLang == 'da' ? '' : 'en/') : (AU.pageLang == 'da' ? 'da/' : '');
        var baseUrl = jQuery('base') == null ? location.host : (jQuery('base').attr('href') + baseUrlAppend);
        if (baseUrl.indexOf('http') === -1) {
            baseUrl = 'https:' + baseUrl;
        }
        var engineId = typeof window.cludoEngineId == 'undefined' || window.cludoEngineId === 0 ? (AU.pageLang == 'da' ? 2151 : 8956) : window.cludoEngineId;
        var filters = baseUrl.indexOf('www.au.dk') > -1 || (typeof window.cludoEngineId != 'undefined' && window.cludoEngineId > 0) ? {} : { 'DomainName': [baseUrl] };
        jQuery.getScript('//customer.cludo.com/scripts/bundles/search-script.min.js', function () {
            var cludoSettings = {
                customerId: 511,
                engineId,
                searchUrl,
                searchInputs: ['cludo-search-form', 'cludo-search-content-form', 'cludo-search-mobile-form'],
                initSearchBoxText: '',
                loading: "<img src='//customer.cludo.com/img/balls.svg' alt='Loading' class='loading' role='progressbar' />",
                endlessScroll: { stopAfterPage: 3, resultsPerPage: 10, bottomOffset: 700 },
                language: AU.pageLang,
                disableAutocomplete: typeof window.disableCludoAutocomplete !== 'undefined' && window.disableCludoAutocomplete,
                filters,
                customCallbackAfterSearch: function () {
                    AU.search.checkForBanners();
                }
            };
            CludoSearch = new Cludo(cludoSettings);
            CludoSearch.init();

            jQuery('.cludo-searchall a').on('click', function (e) {
                e.preventDefault();
                var href = jQuery(this).attr('href');
                var hash = location.hash;
                location.href = href + hash;
            });
        });
        jQuery('#cludo-search-form-input').on('focus', function () {
            jQuery('#cludo-search-form').addClass('active');
        }).on('focusout', function () {
            jQuery('#cludo-search-form').removeClass('active');
        });
    },
    checkForBanners: function () {
        var banners = jQuery('.cludo-banner .aubanner');
        banners.each(function () {
            var links = jQuery('a', this);
            if (links.length > 0) {
                jQuery(this).wrapInner('<a href="' + links.eq(0).attr('href') + '"></a>');
                links.hide();
            }
        });
    }
};