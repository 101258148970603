/**
 * Titel: SOCIAL
 * Beskrivelse: #SoME-deling
 * Status: er kun i nogen grad omskrevet fra 2011/2015-udgaven. Kunne med fordel ændres til ES6/React (hvis behovet er der?)
 */

AU.social = {
    options: {
        config: {
            mode: 'sharing',
            container: '.au_social_sharebox',
            tracking: '/virtual/social/sharing/',
            links: [
                {
                    classname: 'facebook',
                    text: 'AU på Facebook',
                    url: 'https://www.facebook.com/UniAarhus'
                },
                {
                    classname: 'twitter',
                    text: 'AU på Twitter',
                    url: 'https://twitter.com/AarhusUni'
                },
                {
                    classname: 'linkedin',
                    text: 'AU på LinkedIn',
                    url: 'http://www.linkedin.com/company/aarhus-university-denmark-?trk=hb_tab_compy_id_4648'

                },
                {
                    classname: 'youtube',
                    text: 'AU på YouTube',
                    url: 'http://www.youtube.com/user/AarhusUniversity'

                },
                {
                    classname: 'vimeo',
                    text: 'AU på Vimeo',
                    url: 'http://vimeo.com/channels/181624'
                },
                {
                    classname: 'instagram',
                    text: 'AU på Instagram',
                    url: 'http://instagram.com/aarhusuni'
                }
            ],
            sharing: [
                {
                    classname: 'facebook',
                    text: 'Del på Facebook'
                }, {
                    classname: 'twitter',
                    text: 'Del på Twitter'
                }, {
                    classname: 'linkedin',
                    text: 'Del på LinkedIn'
                }, {
                    classname: 'email',
                    text: 'Send til en ven'
                }
            ],
            direction: '',
            icons: true,
            spacing: false,
            title: '',
            url: '',
            size: 'medium',
            color: 'real',
            initFunc: null
        }
    },
    sharers: [],
    newsSharer: {
        config: {
            container: '#au_content .au_news-single-item',
            tracking: '/virtual/ttnews/sharing/',
            direction: 'horizontal'
        }
    },
    peopleXsSharer: {
        config: {
            container: '#au_content .peoplexs-single',
            tracking: '/virtual/peoplexs/sharing/',
            direction: 'horizontal'
        }
    },
    mergeOptions: function (sharer) {
        if (!sharer.hasOwnProperty('config')) {
            sharer.config = {};
        }

        for (var conf in this.options.config) {
            if (!sharer.config.hasOwnProperty(conf)) {
                sharer.config[conf] = this.options.config[conf];
            }
        }
    },
    renderButtons: function (sharer) {

        var html = "";
        if (sharer.config.mode === 'sharing') {

            var shareTitle = sharer.config.title;
            var shareUrl = sharer.config.url;

            var direction = sharer.config.direction;
            var icons = sharer.config.icons;
            var spacing = sharer.config.spacing;
            var services = sharer.config.sharing;

            var dirClass = direction === '' ? '' : ' ' + direction;
            var iconClass = icons ? ' icons' : '';
            var spacedClass = spacing ? ' spacing' : '';

            html += '<ul class="resetlist au_social_sharing' + dirClass + iconClass + spacedClass + ' services-' + services.length + '">';
            for (var i = 0; i < services.length; i++) {
                var append = services[i].classname === 'email' ? '<ul class="resetlist"><li class="outlook"><a title="' + (AU.pageLang === 'da' ? 'Send via e-mailklienten på din computer' : 'Send via the email client on your computer') + '" href="mailto:?subject=' + shareTitle + '&body=' + shareUrl + '">' + (AU.pageLang === "da" ? 'E-mail' : 'Email') + '</a></li><li class="gmail"><a title="Send via Gmail" target="_blank" href="http://www.addtoany.com/add_to/google_gmail?linkurl=' + shareUrl + '">Gmail</a></li><li class="yahoo"><a title="Send via Yahoo Mail" target="_blank" href="http://www.addtoany.com/add_to/yahoo_mail?linkurl=' + shareUrl + '">Yahoo Mail</a></li><li class="outlookcom"><a title="Send via outlook.com" target="_blank" href="http://www.addtoany.com/add_to/outlook_com?linkurl=' + shareUrl + '">Outlook.com</a></li></ul>' : "";
                html += '<li class="' + services[i].classname + '"><a href="' + shareUrl + '" data-title="' + shareTitle + '" data-url="' + shareUrl + '">' + services[i].text + '</a>' + append + '</li>';
            }
            html += '</ul>';

        } else {

            var links = sharer.config.links;
            var color = sharer.config.color === 'real' ? '' : ' style="color: ' + sharer.config.color + ';"';

            html += '<ul class="resetlist au_social_linking ' + sharer.config.size + '">';
            for (var j = 0; j < links.length; j++) {
                html += '<li class="' + links[j].classname + '"><a' + color + ' title="' + links[j].text + '" href="' + links[j].url + '"></a></li>';
            }
            html += '</ul>';
        }

        sharer.container.append(html);
    },
    disableAddThisForOldNews: function () {
        $('.au_news-single-item .addthis_toolbox').hide();
    },
    trackingFunc: function (sharer) {
        $('ul.au_social_sharing a', sharer.container).on('click', function () {
            var service = $(this).parent().attr('class');
            var trackerLink = sharer.config.tracking + service + '/' + sharer.config.url;
            AU.tracking.trackSingle(trackerLink);
        });
    },
    init: function () {

        if (this.sharers.length > 0) {

            var c = this.options.config;
            if (AU.pageLang !== 'da') {
                c.links[0].text = 'AU on Facebook';
                c.links[1].text = 'AU on Twitter';
                c.links[2].text = 'AU on LinkedIn';
                c.links[3].text = 'AU on YouTube';
                c.links[4].text = 'AU on Vimeo';
                c.links[5].text = 'AU on Instagram';
                c.sharing[0].text = 'Facebook share';
                c.sharing[1].text = 'Twitter share';
                c.sharing[2].text = 'LinkedIn share';
                c.sharing[3].text = 'Send to a friend';
            }

            c.title = $('title').text();
            c.url = location.href;
            c.initFunc = function (box) {
                if (box.config.mode === 'sharing') {
                    var s = AU.social;
                    s.fb.setClick(box);
                    s.twitter.setClick(box);
                    s.linkedin.setClick(box);
                    s.mail.setClick(box);
                }
            };

            for (var i = 0; i < this.sharers.length; i++) {
                var s = this.sharers[i];

                if (!s.loaded) {
                    this.mergeOptions(s);
                    s.container = $(s.config.container).eq(0);
                    this.renderButtons(s);
                    s.loaded = true;
                    s.config.initFunc(s);

                    this.trackingFunc(s);
                }
            }
        }
    },
    fb: {
        openShareDialog: function (url) {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, '', 'width=600,height=450');
        },
        setClick: function (sharer) {
            $('ul.au_social_sharing .facebook a', sharer.container).on('click', function (e) {
                e.preventDefault();
                var shareUrl = $(this).data('url');
                AU.social.fb.openShareDialog(shareUrl);
            });
        }
    },
    twitter: {
        openShareDialog: function (title, url) {
            url = encodeURI(url);
            title = encodeURI(title);
            window.open('https://twitter.com/intent/tweet?text=' + title + '&url=' + url, '', 'width=550,height=450');
        },
        setClick: function (sharer) {
            $('ul.au_social_sharing .twitter a', sharer.container).on('click', function (e) {
                e.preventDefault();
                var shareTitle = $(this).data('title');
                var shareUrl = $(this).data('url');
                AU.social.twitter.openShareDialog(shareTitle, shareUrl);
            });
        }
    },
    linkedin: {
        openShareDialog: function (url) {
            url = encodeURI(url);
            window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url, '', 'width=850,height=550');

        },
        setClick: function (sharer) {
            $('ul.au_social_sharing .linkedin a', sharer.container).on('click', function (e) {
                e.preventDefault();
                var shareUrl = $(this).data('url');
                AU.social.linkedin.openShareDialog(shareUrl);
            });
        }
    },
    mail: {
        setClick: function (sharer) {
            $('ul.au_social_sharing .email > a', sharer.container).on('click', function (e) {
                e.preventDefault();
                $('> ul', $(this).parent()).toggle();
                $(this).toggleClass('active');
            });
        }
    }
};

AU.social.instashow = {
    loaded: false,
    load: function (callback) {
        if (typeof callback === 'function') {
            if (callback()) {
                $.getScript('https://apps.elfsight.com/p/platform.js');
                AU.social.instashow.loaded = true;
            }
        }
    }
};

AU.social.yottie = {
    loaded: false,
    load: function (callback) {
        if (typeof callback === 'function') {
            if (callback()) {
                $.getScript('https://cdn.au.dk/vendor/js/jquery.yottie.bundled.js');
                AU.social.yottie.loaded = true;
            }
        }
    }
};
