AU.piechart = {
    options: {
        config: {
            container: '.pie-container',
            canvas: '#pie1',
            width: 250,
            height: 250,
            colors: ['#b9e5fb', '#6ecff6', '#00bdf2', '#00aeef', '#008bbf', '#006991', '#004563', '#37373a', '#4c4d4f', '#6d6e71', '#818285', '#9d9fa2', '#bcbdc0'],
            data: [{ value: 50, label: 'Data 1' }, { value: 50, label: 'Data 2' }]
        }
    },
    mergeOptions: function (pie) {
        if (!pie.hasOwnProperty('config')) {
            pie.config = {};
        }

        for (let conf in this.options.config) {
            if (!pie.config.hasOwnProperty(conf)) {
                pie.config[conf] = this.options.config[conf];
            }
        }

        if (!pie.hasOwnProperty('data')) {
            pie.data = {};
        }

        for (let d in this.options.data) {
            if (!pie.data.hasOwnProperty(d)) {
                pie.data[d] = this.options.data[d];
            }
        }
    },
    highlight: function (col, amt) {

        let usePound = false;

        if (col[0] === "#") {
            col = col.slice(1);
            usePound = true;
        }

        const num = parseInt(col, 16);
        let r = (num >> 16) + amt;

        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }

        let g = (num & 0x0000FF) + amt;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    },
    getData: function (pie) {
        let data = [];
        let colorIndex = 0;
        for (let i = 0; i < pie.config.data.length; i++) {
            const pd = pie.config.data[i];

            if (colorIndex === pie.config.colors.length - 1) {
                colorIndex = 0;
            }

            data.push({
                value: pd.value,
                label: pd.label,
                color: pie.config.colors[colorIndex],
                highlight: this.highlight(pie.config.colors[colorIndex], 10)
            });

            colorIndex++;
        }

        return data;
    },
    createLegend: function (pie, canvas) {
        const helpers = Chart.helpers;
        const legendHolder = document.createElement('div');
        legendHolder.innerHTML = pie.generateLegend();
        helpers.each(legendHolder.firstChild.childNodes, function (legendNode, index) {
            helpers.addEvent(legendNode, 'mouseover', function () {
                let activeSegment = pie.segments[index];
                activeSegment.save();
                activeSegment.fillColor = activeSegment.highlightColor;
                pie.showTooltip([activeSegment]);
                activeSegment.restore();
            });
        });
        helpers.addEvent(legendHolder.firstChild, 'mouseout', function () {
            pie.draw();
        });
        canvas.parentNode.parentNode.appendChild(legendHolder.firstChild);
    },
    pies: [],
    init: function () {
        for (let i = 0; i < this.pies.length; i++) {
            const pie = this.pies[i];
            if (!pie.loaded) {
                pie.id = i;
                this.mergeOptions(pie);
                const mountNode = document.querySelector(pie.config.container);
                const visible = mountNode && AU.helpers.isElementPartlyInViewport(mountNode) && getComputedStyle(mountNode)['visibility'] != 'hidden';
                if (visible) {
                    $(pie.config.container).append('<div><canvas id="' + pie.config.canvas.replace('#', '') + '" width="' + pie.config.width + '" height="' + pie.config.height + '"></canvas></div>');
                    pie.canvas = document.querySelector(pie.config.canvas);
                    const chartPie = new Chart(pie.canvas.getContext('2d')).Pie(this.getData(pie), { tooltipTemplate: "<%if (label){%><%=label.length > 15 ? (label.substring(0, 15) + '...') : label %> : <%}%><%= value %>%", animation: true });
                    this.createLegend(chartPie, pie.canvas);
                    pie.loaded = true;
                }
            }
        }

        const pieLegend = $('.pie-legend');
        if (pieLegend !== null) {
            pieLegend.addClass('resetlist');
        }
    }
};

AU.scroll.push(function () {
    AU.piechart.init();
});