/**
 * Titel: DATABOX
 * Beskrivelse: dynamiske søgebokse, som er strøet ud med lind hånd over hele websitet
 * Status: er fuldstændig omskrevet til ES6/React og indlæses nu via components-biblioteket for at holde React ude af dette projekt
 */

$.ajaxSetup({
    cache: true
});

AU.databox = {
    boxes: [],
    scriptLoaded: false,
    loadExternal: function () {
        const db = new AUDatabox(AU.databox.boxes);
        db.init();
    },
    init: function () {
        if (this.boxes.length > 0) {
            if (!this.scriptLoaded) {
                const componentsUrl = AU.cdn + '/components/umd/databox.js?v=' + AU.version;
                jQuery.getScript(componentsUrl, function () {
                    AU.databox.loadExternal();
                    AU.databox.scriptLoaded = true;
                });
            } else {
                this.loadExternal();
            }
        }
    }
};