/**
 * @author TRH
 */

/*** GLOBALT AU OBJEKT ***/

var AU = {
    pageLang: 'da', // Default sprog
    cdn: 'https://cdn.au.dk',
    version: '202107061345',
    bss: false,
    ready: [],
    load: [],
    resize: [],
    scroll: [],
    navigation: {}
};