/**
 * Titel: NAVIGATION
 * Beskrivelse: initialisering af menuer, fade (som stadig bruges til news), brødkrummer m.m.
 * Status: er kun i mindre grad blevet modificeret fra 2011/2015-udgaven
 */

AU.navigation.menu = {
    setClick: function () {
        jQuery('button.menu-icon').on('click', function () {
            var headerMenu = jQuery(this).parents('.header-menu');
            if (headerMenu.hasClass('open')) {
                headerMenu.removeClass('open');
                if (!AU.bss) {
                    jQuery('.search').removeClass('hide');
                }
            } else {
                headerMenu.addClass('open');
                if (!AU.bss) {
                    jQuery('.search').addClass('hide');
                }
            }
        });
    },
    setUtility: function () {
        jQuery(document).on('show.zf.dropdown', 'header .dropdown-pane', function () {
            AU.databox.init();
            AU.alphabox.init();
        });
    },
    setBreadcrumb: function () {
        var bc = jQuery('#au_breadcrumb');
        var crumbs = jQuery('> a', bc);
        var crumbCutOff = 3;
        if (crumbs.length > crumbCutOff) {
            bc.addClass('collapse');
            crumbs.eq(0).after('<div class="bc-expand">&nbsp;<span class="icon-bullet_rounded"></span>&nbsp;<span>&hellip;</span>&nbsp;<span class="icon-bullet_rounded"></span></div>');
            jQuery('.bc-expand span').on('click', function () {
                bc.toggleClass('collapse');
            });
        }
    },
    addExtraMenuItem: function () { // Fix til den mobile menu, hvor der indsættes et ekstra top-level menupunkt
        var topLevelMenuItems = jQuery('.top-bar > div > ul.menu li.has-submenu');
        topLevelMenuItems.each(function () {
            var topLevelMenuItem = jQuery('> a', this);
            var topLevelSubMenu = jQuery('ul.submenu', this);
            topLevelSubMenu.prepend('<li role="menuitem" class="show-for-small-only is-submenu-item is-dropdown-submenu-item is-accordion-submenu-item"><a href="' + topLevelMenuItem.attr('href') + '">' + topLevelMenuItem.text() + '</a></li>');
        });
    },
    setTopBar: function () {
        var topBarLinks = jQuery('body:not(.department):not(.neutral) .top-bar .menu > li > a, body.department:not(.omnibus) .top-bar.custom .menu > li > a');
        var headerMenu = jQuery('.header-menu');
        topBarLinks.each(function () {
            var topBarContent = jQuery('.top-bar-content', jQuery(this).parent());
            jQuery(this).on('mouseover', function () {
                if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                    AU.navigation.topBarTimeout = setTimeout(function () {
                        topBarContent.show().css('width', headerMenu.css('width'));
                    }, 300);
                }
            }).on('mouseout', function () {
                if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                    topBarContent.hide();
                    clearTimeout(AU.navigation.topBarTimeout);
                }
            }).on('click', function (e) {
                if (AU.helpers.isMobileDevice()) {
                    e.preventDefault();
                    jQuery('.top-bar-content').not(topBarContent).hide();
                    topBarContent.toggle().css('width', headerMenu.css('width'));
                }
            });
        });

        jQuery('.top-bar-content').on('mouseover', function () {
            if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                jQuery(this).show();
            }
        }).on('mouseout', function () {
            if (AU.responsive.state.NAME != 'PHONE' && !AU.helpers.isMobileDevice()) {
                jQuery(this).hide();
            }
        });

        if (typeof window.selectedUM !== 'undefined' && window.selectedUM !== 100) { // 100 = ingenting.
            jQuery('.top-bar .menu > li:eq(' + (window.selectedUM - 1) + ')').addClass('active');
        }
    }
};

AU.navigation.search = {
    setClick: function () {
        jQuery('#tx_googlequery_searchform_ss_q, #cludo-search-form-input').on('focusout', function () {
            jQuery('#tx_googlequery_searchform_ss_q, #cludo-search-form-input').val('');
            jQuery('.section-header').removeClass('opensearch');
        });

        jQuery('.au_searchbox').on('click', function () {
            jQuery('.section-header').addClass('opensearch');
            jQuery('#tx_googlequery_searchform_ss_q, #cludo-search-form-input').focus();
        });
    }
};

AU.navigation.frontpage = {
    setTargetGroups: function () {
        var buttons = jQuery('.target-groups .button');
        var groups = jQuery('.target-groups + div > div > .csc-default');
        var toggleGroup = function (i) {
            var group = groups.eq(i);
            group.toggleClass('active');
            groups.not(group).removeClass('active');
        }
        buttons.each(function (i, btn) {
            var button = jQuery(btn);
            button.on('click', function (e) {
                e.preventDefault();
                jQuery(this).toggleClass('active');
                buttons.not(button).removeClass('active');
                toggleGroup(i);
            });
        });
    }
};
