/*
 * Title: TRACKING
 * Description: Google Analytics tracking
 */

AU.tracking = {
    trackers: [
        {
            account: 'UA-2142081-1',
            name: 'default'
        },
        {
            account: 'UA-113308028-1',
            name: 'search'
        }
    ],
    init: function () {

        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

        //window.ga_debug = {trace: true};

        for (var i = 0; i < AU.tracking.trackers.length; i++) {

            var t = AU.tracking.trackers[i];

            ga('create', t.account, 'auto', t.name);
            ga(t.name + '.require', 'displayfeatures');
            ga(t.name + '.require', 'linkid', 'linkid.js');
            ga(t.name + '.require', 'GTM-MGFPHKR');
            ga(t.name + '.set', 'anonymizeIp', true);

            ga(t.name + '.send', 'pageview');
        }
    },
    isFileLink: function (href) {
        var filetypes = /\.(zip|exe|pdf|doc|xls|xlsx|ppt|pptx|pps|ppsx|mp(3|4)|eps|docx|dotx|rar|csv|gz|dmg|xml|odt|odp|ods|odg|sxw|tex|rtf|tgz|tar|dvi)$/i;
        return href.match(filetypes);
    },
    isExternalLink: function (href) {
        return !href.match(/\.au\.dk/) && !href.match(/https?\:\/\/au\.dk/) && href.match(/^https?\:\/\//i);
    },
    isMailtoLink: function (href) {
        return href.match(/^mailto\:/i);
    },
    setEvents: function (item, trackNormalLinks) {
        var t = AU.tracking;
        var setEventListener = function (link, href) {
            link.addEventListener('click', function () {
                if (t.isFileLink(href)) {
                    var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
                    for (var i = 0; i < AU.tracking.trackers.length; i++) {
                        ga(AU.tracking.trackers[i].name + '.send', 'event', 'Download', extension[0], href);
                    }
                } else if (t.isExternalLink(href)) {
                    var extLink = href.replace(/^https?\:\/\//i, '');
                    for (var i = 0; i < AU.tracking.trackers.length; i++) {
                        ga(AU.tracking.trackers[i].name + '.send', 'event', 'External links', 'Click', extLink);
                    }
                } else if (t.isMailtoLink(href)) {
                    var mailLink = href.replace(/^mailto\:/i, '');
                    for (var i = 0; i < AU.tracking.trackers.length; i++) {
                        ga(AU.tracking.trackers[i].name + '.send', 'event', 'Email', 'Click', mailLink);
                    }
                } else if (trackNormalLinks) {
                    var trackerLink = item.virtual;
                    var hasImg = link.querySelector('img');
                    if (hasImg) {
                        trackerLink += 'image/' + href;
                    } else {
                        trackerLink += 'text/' + AU.helpers.rewriteLink(link.innerText);
                    }

                    if (typeof ga !== 'undefined') {
                        for (i = 0; i < AU.tracking.trackers.length; i++) {
                            ga(AU.tracking.trackers[i].name + '.send', 'pageview', trackerLink);
                        }
                    }
                }
            });
        };

        for (var j = 0; j < item.links.length; j++) {
            var link = item.links[j];
            var href = link.getAttribute('href');
            if (href) {
                setEventListener(link, href);
            }
        }
    },
    trackSingle: function (trackerLink) {
        var i;
        if (typeof ga !== 'undefined') {
            for (i = 0; i < AU.tracking.trackers.length; i++) {
                ga(AU.tracking.trackers[i].name + '.send', 'pageview', trackerLink);
            }
        }
    }
};